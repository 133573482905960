import {GetServerSideProps} from 'next';

import {NotAuthorized} from '@tetra-next/nsui';

export default function Custom401() {
  return <NotAuthorized />;
}

export const getServerSideProps: GetServerSideProps = async ({locale, req}) => {
  return {
    props: {
      messages: {
        ...require(`@messages/components/${locale}.json`),
        ...require(`@tetra-next/nsui/translations/${locale}.json`),
      },
    },
  };
};
